import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerSocialNetworks() {
    var url = ConfigAPI.baseURL + "social-networks" + Session.getToken();
    return instance.get(url);
  },
  mostrarSocialNetworks(id) {
    var url = ConfigAPI.baseURL + "social-networks/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSocialNetworks(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "social-networks" + Session.getToken();
    return instance.post(url,params);
  },
  editarSocialNetworks(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "social-networks/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSocialNetworks(id) {
    var url = ConfigAPI.baseURL + "social-networks/"+ id + Session.getToken();
    return instance.delete(url);
  },   
 
  obtenerSocialNetworksInformation() {
    var url = ConfigAPI.baseURL + "social-networks-information" + Session.getToken();
    return instance.get(url);
  },
  mostrarSocialNetworksInformation(id) {
    var url = ConfigAPI.baseURL + "social-networks-information/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSocialNetworksInformation(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="banners") {
        formData.append(key, data[key]);
      }

      if(key=="banners") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "social-networks-information" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarSocialNetworksInformation(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="banners") {
        formData.append(key, data[key]);
      }

      if(key=="banners") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "social-networks-information/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarSocialNetworksInformation(id) {
    var url = ConfigAPI.baseURL + "social-networks-information/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
}

export default servicesAPI;

<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Redes Sociales - Información</h4>
            <div class="small text-muted">Visualización de toda la información necesaria para las acciones</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar">
              <i class="fa fa-plus"></i> Agregar
            </b-button>                       
          </b-col>                       
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                    
                    v-if="table.items.length">                       
                
                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="6">
                        <b-row>
                          <b-col md="12">
                            <table class="table table-hover table-sm">
                              <tbody>
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Edades</td>
                                  <td class="social-networks-information-value-table-custom align-middle">
                                    {{row.item.age}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Sexos</td>
                                  <td class="social-networks-information-value-table-custom align-middle">
                                    {{row.item.sex}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Segmentación Geográfica</td>
                                  <td class="social-networks-information-value-table-custom align-middle">
                                    {{row.item.geographic_segmentation}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Intereses</td>
                                  <td class="social-networks-information-value-table-custom align-middle">
                                    {{row.item.interests}}
                                  </td>
                                </tr>                                
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Presupuesto Mensual</td>
                                  <td class="social-networks-information-value-table-custom align-middle social-networks-information-budget-monthly">
                                    {{row.item.budget_monthly}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Cronopost</td>
                                  <td class="social-networks-information-value-table-custom align-middle">                                  
                                    <b-link :href="row.item.chronopost" target="_blank">
                                      Ver Cronopost
                                      <i class="fa fa-angle-double-right"></i>
                                    </b-link>                                    
                                  </td>
                                </tr>                                                                  
                                <tr>
                                  <td class="social-networks-information-title-table-custom align-middle">Observaciones</td>
                                  <td class="social-networks-information-value-table-custom align-middle">
                                    {{row.item.observations}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="6">
                        <b-row>
                          <b-col md="12">
                              <div class="social-networks-informations-title-banners">Banners</div>

                              <div v-if="row.item.banners">
                                <viewer :images="row.item.banners">
                                  <b-img v-for="value in row.item.banners" v-bind:key="value"
                                        :src="value" 
                                        fluid 
                                        class="social-networks-informations-banners" />
                                </viewer>
                              </div>
                              <div v-else>
                                <b-alert show variant="warning">No se cargó ningun banner</b-alert>
                              </div>  
                          </b-col>
                        </b-row>
                      </b-col>                      
                    </b-row>
                  </b-card>
                </template>

                <template v-slot:cell(date)="data">
                  <b>{{moment(data.item.date).format('DD MMMM YYYY')}}</b>
                </template>

                <template v-slot:cell(chronopost)="data">
                  <b-link :href="data.item.chronopost" target="_blank">
                    Ver Cronopost
                    <i class="fa fa-angle-double-right"></i>
                  </b-link>
                </template> 

                <template v-slot:cell(budget_monthly)="data">
                  <div v-if="data.item.budget_monthly" class="social-networks-information-budget-monthly">
                    <div v-if="data.item.budget_monthly.length >50" :title="data.item.budget_monthly">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.budget_monthly.substr(0, 50)}}
                    </div>
                    <div v-else>                      
                      {{data.item.budget_monthly}}
                    </div>
                  </div>
                </template> 

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>                      
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>                             

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>
        
        <b-row>
          <b-col md="6">                
            <b-form-group label="Edades">
              <b-form-textarea                                
                v-model="crud.form.age"
                size="sm"
                placeholder="Ingresar la/las edades"
              ></b-form-textarea>
            </b-form-group>      
          </b-col> 

          <b-col md="6">                
            <b-form-group label="Sexos">
              <b-form-textarea                                
                v-model="crud.form.sex"
                size="sm"
                placeholder="Ingresar el/los sexos"
              ></b-form-textarea>
            </b-form-group>      
          </b-col> 

          <b-col md="6">                
            <b-form-group label="Segmentación Geográfica">
              <b-form-textarea                                
                v-model="crud.form.geographic_segmentation"
                size="sm"
                placeholder="Ingresar la segmentación geográfica"
              ></b-form-textarea>
            </b-form-group>      
          </b-col>

          <b-col md="6">                
            <b-form-group label="Intereses">
              <b-form-textarea                                
                v-model="crud.form.interests"
                size="sm"
                placeholder="Ingresar los intereses"
              ></b-form-textarea>
            </b-form-group>      
          </b-col>

          <b-col md="6">                
            <b-form-group label="Presupuesto Mensual">
              <b-form-textarea                                
                v-model="crud.form.budget_monthly"
                size="sm"
                placeholder="Ingresar el presupuesto mensual"
              ></b-form-textarea>
            </b-form-group>      
          </b-col>

          <b-col md="6">                
            <b-form-group label="Cronopost">
              <b-form-input type="url"
                            size="sm"
                            v-model="crud.form.chronopost"
                            required
                            placeholder="Ingresar el Cronopost">
              </b-form-input>
            </b-form-group>      
          </b-col> 

          <b-col md="6">                
            <b-form-group label="Display">
              <Imagen :images="crud.form.banners" 
                      @load-image="crud.form.banners = $event" 
                      typeImage="banners" 
                      :multipleImage="true" 
                      :compressImage="true"/>
            </b-form-group>                            
          </b-col> 

          <b-col md="6">                
            <b-form-group label="Observaciones">
              <b-form-textarea                                
                v-model="crud.form.observations"
                size="sm"
                placeholder="Ingresar las observaciones"
              ></b-form-textarea>
            </b-form-group>      
          </b-col>           
        </b-row>        

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import moment from 'moment'
  
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'v-viewer'
  import Vue from 'vue'
  Vue.use(Viewer)

  export default {
    components: {
      Imagen,
    },     
    data: () => {
      return {      
        access: {
          module_id: Modules.REDES_SOCIALES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudInformation',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'date', label: 'Fecha', sortable: true},
            {key: 'chronopost', label: 'Cronopost'},
            {key: 'budget_monthly', label: 'Presupuesto Mensual'},
            {key: 'observations', label: 'Observaciones'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            date: '',
            geographic_segmentation: '',
            age: '',
            sex: '',
            interests: '',
            chronopost: '',
            banners: [],
            budget_monthly: '',
            observations: ''
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        arr: {
          information: []
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.information.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },        
      load() {
        var result = serviceAPI.obtenerSocialNetworksInformation()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.information = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true
          }           
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.date = ''
        this.crud.form.geographic_segmentation = ''
        this.crud.form.age = ''
        this.crud.form.sex = ''
        this.crud.form.interests = ''
        this.crud.form.chronopost = ''
        this.crud.form.banners = []
        this.crud.form.budget_monthly = ''
        this.crud.form.observations = ''

        this.modal.form.title = "Nuevo Registro de Información"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id     
        this.crud.form.date = item.date
        this.crud.form.geographic_segmentation = item.geographic_segmentation
        this.crud.form.age = item.age
        this.crud.form.sex = item.sex
        this.crud.form.interests = item.interests
        this.crud.form.chronopost = item.chronopost
        this.crud.form.banners = item.banners
        this.crud.form.budget_monthly = item.budget_monthly
        this.crud.form.observations = item.observations

        if(item.banners) {          
          this.crud.form.banners = []
          item.banners.forEach(element => {
            this.crud.form.banners.push({ path: element })
          });                    
        } else {
          this.crud.form.banners = []
        }

        this.modal.form.title = "Editar Información"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.date = moment(item.date).format('DD MMMM YYYY')

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') con fecha ' + this.crud.form.date + '?', {
          title: 'Borrar Registro',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSocialNetworksInformation(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.banners.forEach((value, key) => {
          image.append(key, value.path);
        })

        if (this.crud.form.id) {
          var result = serviceAPI.editarSocialNetworksInformation(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregarSocialNetworksInformation(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
    }    
  }
</script>

<style>
  .social-networks-information-budget-monthly {
    color: darkgreen;
    font-weight: bold;
  }
  .social-networks-information-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .social-networks-information-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }  
  .social-networks-informations-title-banners {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 5px;
  }  
  .social-networks-informations-banners {
    height: 80px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
</style>